@charset "utf-8";

/*######################################################################################################
 *
 * Animation
 *
 *####################################################################################################*/

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

/*######################################################################################################
 *
 * Element
 *
 *####################################################################################################*/

/*
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  background:transparent;
}

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display:block;
}

ul,ol,li {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

input, select {
  vertical-align:middle;
}
*/

/**************************************************
 * html
 **************************************************/
html {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/**************************************************
 * body
 **************************************************/
body {
  font-size: 80%;
  line-height: 160%;
  font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Meiryo UI','メイリオ','Meiryo','ＭＳ Ｐゴシック', sans-serif;
  color: #333333;
  letter-spacing: 0.05em;
  width: 100%;
  height: auto;
  background: #cccccc;
  margin: 0 auto;
  height: 100%;
}

html > body {
  font-size: 14px;
  line-height:160%;
}


/**************************************************
 * input
 **************************************************/
input:focus {
  outline: none;
}

/**************************************************
 * a
 **************************************************/
a {
  margin:0;
  padding:0;
  border:0;
  font-size:100%;
  vertical-align:baseline;
  color: #113bb7;
  text-decoration:underline;
  overflow:hidden;
  outline:none;
}

a:link, a:visited, a:active {
  color: #113bb7;
}

a:hover,
a:focus {
  text-decoration:underline;
  color: #ff950c;
}

a:before {
  text-decoration:underline;
  display:inline-block;
}

a:hover:before {
  text-decoration:none;
}

p {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

label {
  font-weight: 700;
}

label.font-18 {
  font-size:18px;
}

label.font-28 {
  font-size:28px;
}

input.psize-9::placeholder {
  font-size: 90%;
}


/**************************************************
 * ins
 **************************************************/
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}


/**************************************************
 * mark
 **************************************************/
mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}


/**************************************************
 * del
 **************************************************/
del {
  text-decoration: line-through;
}


/**************************************************
 * abbr
 **************************************************/
abbr[title],
dfn[title] {
  border-bottom:1px dotted #000;
  cursor:help;
}


/**************************************************
 * table
 **************************************************/
table {
  border-collapse:collapse;
  border-spacing:0;
}

table > tbody > tr input[type=checkbox] {
  width: 18px;
  height: 18px;
}

table > tbody > tr > td.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table > tbody > tr > td.ellipsis:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

/**************************************************
 * hr
 **************************************************/
hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}


/**************************************************
 * address
 **************************************************/
address{
  font-style:normal;
}


/**************************************************
 * img
 **************************************************/
img {
    vertical-align: top;
    font-size:0;
    line-height: 0;
}


/**************************************************
 * header
 **************************************************/
@media (min-width: 576px) {
  header {
    height:100px;
  }
}

@media (min-width: 1200px) {
  header {
    height:80px;
  }
}

header > .container {
  padding: 15px;
  min-height:100%;
  border-bottom: 1px solid #999999;
  color: #ffffff;
  background: #000000;
}

header > .container img {
  margin-right: 12px;
  float: left;
}

header > .container p {
  padding-top: 10px;
}

header > .container ul {
  margin-top: 1em;
}

header .header-link {
  color: #ffffff;
}

header .header-link:link,
header .header-link:visited {
  color : #ffffff;
}


/**************************************************
 * footer
 **************************************************/
footer {
  height:10px;
}

footer > .container {
  background-color: #000000;
  min-height:100%;
}
footer > .container address {
  padding: 1em 0;
  font-size: 0.8em;
  text-align: center;
  color: #ffffff;
  margin: 1em 0 1em 0;
}


/**************************************************
 * fieldset
 **************************************************/

fieldset.gray {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  border: 2px solid #B8BEB4;
  background-color:#EEEEE6;
}

fieldset > legend {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0 0.25rem;
  width: auto;
}






/*######################################################################################################
 *
 * Class
 *
 *####################################################################################################*/

/**************************************************
 * .content
 **************************************************/
.content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.content > .container {
  min-height: 80%;
  height: auto;
}

.content > .container.sidescreen > div {
  min-height: 80vh;
  height: auto;
}

/**************************************************
 * .container
 **************************************************/
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


.loading {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
  */
  width: 100px;
  height: 100px;
  /*
  margin: -50px 0 0 -50px;
  */
  line-height: 100px;
  font-size: 56px;
  color: #fff;
  text-align: center;
  background-color: #000000;
  opacity: 0.7;
  border-radius: 10px;
}

/**************************************************
 * .fullscreen
 **************************************************/
 .fullscreen {
  background-color: #FFFFFF;
  margin-right: auto;
  margin-left: auto;
}

.fullscreen h1 {
  background-color: #000000;
  color: #fff;
  font-size: 18px;
  margin-top: -2rem;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right:-15px;
  padding: 15px 7px 15px 7px;
}

.fullscreen .outer-box {
  background-color: #EEEEE6;
  border: 1px solid #888888;
  padding: 1em;
  margin-bottom: 2em;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.fullscreen .inner-box {
  background-color: #FFFFFF;
  padding: 3em 0em;
  margin-right: auto;
  margin-left: auto;
}

.fullscreen form {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.fullscreen .title-box {
  background-color: #666666;
  color: #FFFFFF;
  text-align: center;
  height: 50px;
  width: 80%;
  padding: 13px 0px;
  margin-right: 1rem;
}

.fullscreen .input-box {
  background-color: #EEEEE6;
  height: 50px;
  width: 80%;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.fullscreen .input-box .input-group label {
  padding-top: 0.5rem;
  text-align: center;
  width:100%;
}

.fullscreen .input-box .input-group {
  padding-top: 7px;
  padding-right: 20px;
  padding-left:20px;
}

.fullscreen .form-row {
  margin-bottom: 0.5rem;;
}

.fullscreen .input-box > input {
  width: 90%;
}

.fullscreen .btn-area {
  margin-top: 2em;
  margin-bottom: 3em;
  margin-left: -24px;
  text-align: center;
}

.fullscreen form .btn-area input {
  margin-bottom: 1em;
}


.fullscreen form .btn-area > div {
  padding-right: 0px;
  padding-left: 0px;
}

.cache-account-box {
  margin-left: 1rem;
}

.cache-account-box > label {
  cursor: pointer;
}

.info-message,
.error-message {
  margin-bottom:1em;
  font-size:120%;
  color: #ffffff;
  border-radius: 10px;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}


.input-group-append > button[class*="fontawesome-"]::before {
  font-size: 100%;
  margin-right:0px;
  line-height: 1px;
}


/**************************************************
 * .sidescreen
 **************************************************/
.sidescreen {
  background-color: #000000;
}


/**************************************************
 * .side-content
 **************************************************/
.side-content {
  background-color: #f1f5d7;
  margin: 1em 0 0 0;
  padding: 1em;
  min-height: 100%;
}

.side-content.full {
  padding: 0px;
  height: 100%;
}



.side-content.full .nav-tabs>li>a {
  background-color: #333333;
  border-color: #777777;
  color:#fff;
}

/* active tab color */
.side-content.full .nav-tabs>li>a.active,
.side-content.full .nav-tabs>li>a.active:hover,
.side-content.full .nav-tabs>li>a.active:focus {
  color: #fff;
  background-color: #666;
  border: 1px solid #888888;
}

/* hover tab color */
.side-content.full .nav-tabs>li>a:hover {
  border-color: #000000;
  background-color: #111111;
}

.side-content.full .nav-tabs > .nav-item > .nav-link {
  padding: .5rem 1rem;
}

.side-content.full .nav-tabs>li>a.error {
  background-color: #FF3232;
}

.side-content.full .nav-tabs>li>a.active.error {
  background-color: #FF8484;
}



/**************************************************
 * .modal-*
 **************************************************/
.modal-header {
  background-color: #464646;
  color: #FFFFFF;
  border-bottom-color: #6a6a6a;
}

.modal-header .close {
  color: #ffffff;
}

.modal-body {
  background-color: #464646;
  min-height: 200px;
  color: #ffffff;
}

.modal-body div.msg-box {
  height: 100%;
}

.modal-body .msg-box .msg {
  background-color: #000000;
  color: #ffffff;
  padding:1em;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  margin:1em;
  font-size:150%;
  width: 100%;
}

.modal-body .msg.msg-info {
  border-left: 5px solid #3246e2;
}

.modal-body .msg.msg-warn {
  border-left: 5px solid #e5e500;
}

.modal-body .msg.msg-danger {
  border-left: 5px solid #ff0000;
}

.modal-footer {
  background-color: #464646;
  border-top-color: #6a6a6a;
}

.modal-body .help {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #464646;
  border-color: #FFFFFF;
  display:inline-block;
}

body.modal-open {
    overflow: visible;
}


/**************************************************
 * .panel
 **************************************************/
.panel {
  border-radius: 0;
  margin-bottom: 1rem;
}


/**************************************************
 * .panel-heading
 **************************************************/
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.panel-heading.title-green,
.panel-heading.title-red,
.panel-heading.title-blue {
  margin-bottom: 1em;
  border: 1px solid #676767;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(#fdfdfd),to(#bebebe));
  background: -moz-linear-gradient(top,#fdfdfd,#bebebe);
  background: linear-gradient(to bottom, #fdfdfd,#bebebe);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#FFfdfdfd', endColorstr='#FFbebebe');
}

.panel-heading.title-green span,
.panel-heading.title-red span,
.panel-heading.title-blue span {
  display: block;
  text-indent: 1em;
}

.panel-heading.title-green span {
  background: url('../images/icon-green.png') left center no-repeat;
}

.panel-heading.title-red span {
  background: url('../images/icon-red.png') left center no-repeat;
}

.panel-heading.title-blue span {
  background: url('../images/icon-blue.png') left center no-repeat;
}


.panel.dark .panel-heading {
    background-color: #666666;
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.panel.dark .panel-body {
    background-color: #EEEEEE;
    border: 1px solid #444444;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-bottom: 25px;
    word-break: break-all;
}

.font-sm {
  font-size: 0.75rem;
}

.font-md {
  font-size: 1.25rem;
}

.font-lg {
  font-size: 1.5rem;
}

.underline {
  text-decoration: underline;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}


/**************************************************
 * .side-full
 **************************************************/
.panel.side-full {
  margin: 1em 0px 0px;
  background-color: #F1F5D7;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
          box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel.side-full > .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel.side-full .panel-body {
  padding: 15px;
}

.side-full .panel-footer {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel.side-full .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: inherit;
}

.panel.side-full.panel-black {
  border-color: #F1F5D7;
  height: 100%;
}

.panel.side-full.panel-black > .panel-heading {
  color: #ffffff;
  background-color: #000000;
  border-color: #F1F5D7;
}

.panel.side-full.panel-black > .panel-footer {
  color: #ffffff;
  background-color: #F1F5D7;
  border-color: #000000;
}


@media (min-width: 576px) {
  .form-group > label.col-form-label {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .form-group > label.col-form-label {
    text-align: right;
  }
}

.form-group > label.required:after {
  content: "*";
  color: rgb(224, 34, 34);
  font-size: 12px;
  padding-left: 2px;
}

.form-group > label > span.attr:after {
  content: "U";
  color: rgb(34, 34, 224);
  font-size: 12px;
  padding-left: 2px;
}



.form-control.form-post-tel {
  width: 20%;
  display: inline;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #c1c1c1;
}

.button-grp > .btn-left {
    margin-left: 4em;
}
.button-grp > .btn-right {
    float: right;
    margin-right: 4em;
}

.btn-light {
  border: solid;
  border-width:1px;
}

.btn-light:hover {
  border: solid;
  border-width:1px;
}

.btn-link {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: #113bb7;
  font-weight: normal;
  font-size: 100%;
}

.btn-link:hover, .btn-link:focus {
  text-decoration: underline;
  color: #ff950c;
}

textarea.noresize {
  resize:none;
}

textarea.wrapoff {
  white-space: pre;
  word-wrap: normal;
  overflow-wrap: normal;
  overflow-x: scroll;
}

.invalid-feedback {
  display: block;
}

/**************************************************
 * .text-*
 **************************************************/
.text-black {
  color: #000000;
  font-size: 100%;
}

.text-red {
  color: #ff0000;
  font-size: 100%;
}

.text-blue {
  color: #0000FF;
  font-size: 100%;
}

.text-yellow {
  color: #FFD966;
  font-size: 100%;
}

.text-gray {
  color: #808080;
  font-size: 100%;
}

.break {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/**************************************************
 * table.gray-head
 **************************************************/
table.gray-head {
  background-color: #EEEEEE;
  border: 1px solid #999999;
  font-size: 100%;
  font-weight: normal;
  width:100%;
  table-layout:fixed;
  word-wrap:break-word;
}

table.gray-head thead th {
    background-color: #666666;
    padding: 1em;
    border: none;
    color: #ffffff;
}

table.gray-head tbody th {
    background: -webkit-gradient(225deg, #c1c1c1, #666666);
    background: -moz-linear-gradient(225deg, #c1c1c1, #666666);
    background: linear-gradient(225deg, #c1c1c1, #666666);
    padding: 1em;
    color: #ffffff;
}

table.gray-head > tbody > tr {
  border: 1px solid #9a9a9a;
}

table.table-vertical-center td {
  vertical-align: middle !important;
}

table.gray-head-border,
table.gray-head-border thead th,
table.gray-head-border tbody td {
  border: 1px solid #FFFFFF;
}

table > tbody > tr.sum1,
table > tbody > tr > td.sum1 {
  border-top-color:#878787;
  background-color:#878787;
}

table > tbody > tr.sum2,
table > tbody > tr > td.sum2 {
  border-top-color:#B1B1B1;
  background-color:#B1B1B1;
}

@media only screen and (max-width: 992px) {

  .responsive table,
  .responsive thead,
  .responsive tbody,
  .responsive th,
  .responsive td,
  .responsive tr {
    display: block !important;
  }

  .responsive thead tr,
  .responsive tbody th  {
    position: absolute;
    top: -9999px;
    left: -9999px;
    text-align: left !important;
  }

  .responsive tr {
    border: 1px solid #ccc;
  }

  .responsive td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-top: 2rem;
    padding-left: 1rem;
    white-space: normal;
    text-align: left !important;
  }

  .responsive.inline td {
    padding-top:1rem;
    padding-left: 50%;
  }

  .responsive.no-title td {
    padding-top:1rem;
    padding-left: 0.25rem;
  }

  .responsive td:before {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    width: 80%;
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
    content: attr(data-title);
    background: -webkit-gradient(225deg, #c1c1c1, #666666);
    background: -moz-linear-gradient(225deg, #c1c1c1, #666666);
    background: linear-gradient(225deg, #c1c1c1, #666666);
    color: #ffffff;
  }

  .responsive.inline td:before {
    width: 45%;
    padding-right: 10px;
  }

  .responsive.no-title td:before {
    content: none;
  }

}


ul.pagination > li.page-link {
  border: none;
  background-color: transparent;
}

.pagination a.page-link {
  display: inline-block;
}

.pagination .prev:before,
.pagination .next:after,
.pagination .top:before,
.pagination .final:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  vertical-align: middle;
}

.pagination .prev:before {
  content: "\f104\00a0";
}

.pagination .next:after {
  content: "\00a0\f105";
}

.pagination .top:before {
  content: "\f100\00a0";
}

.pagination .final:after {
  content: "\00a0\f101";
}




/**************************************************
 * .help
 **************************************************/
.help {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #000000;
  background-color: #f2dede;
  border-color: #ebccd1;
  display:none;
}

.help p {
  margin-bottom:0px;
}

.help h1:before,
.help h2:before,
.help h3:before,
.help h4:before,
.help h5:before,
.help h6:before {
  content: "■";
}

.help h1.sub:before,
.help h2.sub:before,
.help h3.sub:before,
.help h4.sub:before,
.help h5.sub:before,
.help h6.sub:before {
  content: "◆";
}

.help .caution {
  color: #ff0000;
  margin: 1rem 0rem;
  font-weight: 600;
}

.help .caution:before {
  content: "※";
}






.election-title-box {
  margin-bottom: 2em;
  word-wrap:break-word;
}

.election-title-box p {
  margin-bottom: 0;
}

.election-title:before {
  content: '選挙タイトル： ';
}

.area-title:before {
  content: '選挙区タイトル： ';
}

.candidate-term:before {
  content: '候補申請期間： ';
}

.election-term:before {
  content: '投票期間： ';
}

.elected-count:before {
  content: '定数： '
}

.election-result-data:before {
  content: '開票日： '
}

.election-decided-result-date:before {
  content: '開票結果確定日： '
}

.election-status:before {
  content: 'ステータス : '
}

.election-max-vote-count:before {
  content: '有権者最大投票数 : '
}

.candidate-status:before {
  content: '■候補申請ステータス : ';
}

.candidate-apply:before {
  content: '■申請日時 : ';
}

.send-title:before {
  content: '件名： '
}

.send-date:before {
  content: '送信日時： '
}




h1.highlight {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  border-top: 2px solid #666666;
  border-bottom: 2px solid #666666;
  line-height: 1.6em;
  margin-bottom: 1em;
}

h1.green-box {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  color: #2a8f12;
  border-top: 2px solid #2a8f12;
  border-bottom: 2px solid #2a8f12;
  line-height: 1.6em;
  margin-bottom: 1em;
}

h1.black-box,
h2.black-box,
h3.black-box,
h4.black-box,
h5.black-box,
h6.black-box {
  position: relative;
  padding: 1em 4em 1em 1em;
  background: -webkit-linear-gradient(-155deg, rgba(0, 0, 0, 0) 1.5em, #424242 0%);
  background: -moz-linear-gradient(-155deg, rgba(0, 0, 0, 0) 1.5em, #424242 0%);
  background: linear-gradient(-155deg, rgba(0, 0, 0, 0) 1.5em, #424242 0%);
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: bold;
  border-left:2px solid #666;
  border-bottom:2px solid #666;
}

h1.black-box:after,
h2.black-box:after,
h3.black-box:after,
h4.black-box:after,
h5.black-box:after,
h6.black-box:after {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  width: 1.65507em;
  height: 3.5493em;
  background: -webkit-linear-gradient(to left bottom, rgba(0, 0, 0, 0) 50%, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1));
  background: -moz-linear-gradient(to left bottom, rgba(0, 0, 0, 0) 50%, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1));
  background: linear-gradient(to left bottom, rgba(0, 0, 0, 0) 50%, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1));
  border-bottom-left-radius: 6px;
  box-shadow: -.2em .2em .3em -.1em rgba(0, 0, 0, .15);
  -webkit-transform: translateY(-1.89424em) rotate(-40deg);
  transform: translateY(-1.89424em) rotate(-40deg);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}



h2.voted-rate,
h2.bill-rate {
  font-size: 36px;
  text-align: center;
}

h2.voted-rate:before {
  font-size: 24px;
  content: '投票率：';
}

h2.bill-rate:before {
  font-size: 24px;
  content: '議決権行使率：';
}

h2.voted-rate:after,
h2.bill-rate:after {
  font-size: 24px;
  content: '%';
}

h2.vote-count {
  font-size: 24px;
  text-align: center;
}

h2.vote-count:before {
  content: '(';
}

h2.vote-count:after {
  content: ')';
}

h5.invalid-voted {
  font-size: 14px;
  text-align: right;
}

h5.invalid-voted:before {
  content: '無効票数：';
}

.voted-obtain-detail {
  font-size: 0.75rem;
  text-align: right !important;

}

p.voted-obtain {
  text-align: left;
  margin-top:0.5rem;
  margin-left: 0;
}

p.voted-obtain:before {
  content: '得票数：';
}

p.voted-obtain-rate {
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 0;
}

p.voted-obtain-rate:before {
  content: '得票率：';
}

p.voted-obtain-rate:after {
  content: '%';
}

p.group:before {
  content: 'グループ：'
}

span.rate:after,
p.rate:after {
  content: '%';
  padding-left:0.25em;
  font-size: 80%;
}

span.votes:after,
p.votes:after {
  content: '票';
  padding-left:0.25em;
  font-size: 80%;
}

span.bracket:before,
p.bracket:before {
  content: '(';
}

span.bracket:after,
p.bracket:after {
  content: ')';
}

span.peoples:after,
p.peoples:after,
a.peoples:after{
  content: '人';
  font-size: 80%;
}

span.sum-web:before {
  content: '\00a0 \00a0 \00a0 \00a0 Web:';
}

span.sum-ang:before {
  content: 'Analog:';
}

div.image-preview-area.is-invalid {
  border: 2px solid transparent;
  border-radius: 5px;
  border-color: #FF0000;
}



.panel.wrap-information {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px dotted #7f7f7f;
}

.wrap-information .information {
  background-color: #ffffff;
  border: 1px solid #999999;
  height: 100%;
}

.wrap-information .information h1 {
  border-bottom: 1px dotted #7f7f7f;
  margin: 0.5em;
  padding: 0 0.5em 0.5em 0.5em;
  font-size: 1.2em;
}

.wrap-information .information h1 .date:before {
  content: '-'
}

.wrap-information .information h1 .date:after {
  content: '-'
}

.wrap-information textarea {
  width: 100%;
}

.search-box {
    background-color: #EEEEEE;
    border: 1px solid #999999;
    padding: 1em;
    margin-bottom: 1rem;
}

/*
.search-box .form-group {
  display: inline-block;
}
*/

.search-box .form-group label.col-form-label {
  padding-left: 0;
  text-align: left;
}

.search-box .form-group label.col-form-label:before {
  content: '■';
}

.search-box .form-group input,
.search-box .form-group select {
  width: 100%;
  margin-bottom: 0.5rem;
}



.search-box .form-group select {
  height: 1.75rem;
}

.search-box .detail-link {
  padding-left: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.search-box .btn-group {
  margin-top:1rem;
}

.modal-body .search-box {
  color: #000000;
}

.list-count {
  margin-bottom: 1rem;
}

.list-count .page-size:before {
  content: '表示件数:';
  padding-right: 5px;
}

.list-count .count {
  text-align: right;
}

.list-count .count:before {
  content: '全';
  padding-right: 5px;
}


.voted-count,
.aggregate-count {
  font-size: 20pt;
  color: #FF0000;
  font-weight: bolder;
}

.voted-count:before,
.voted-count:after,
.aggregate-count:before,
.aggregate-count:after {
  font-size: 12pt;
  color: #000000;
  font-weight: normal;
}

.voted-count:before {
  content: '残り';
  padding-right: 5px;
}

.voted-count:after {
  content: '票選択可能です';
  padding-left: 5px;
}



.aggregate-count:before {
  content: '当選者残り';
  padding-right: 5px;
}

.aggregate-count:after {
  content: '人';
  padding-left: 5px;
}

.btn-voted,
.btn-unvote {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  border: solid 2px #CC0000;
  width: 160px;
}

.btn-voted:before,
.btn-unvote:before {
  position: absolute;
  left: 0;
  width: 30px;
  text-align: right;
  padding-left: 2px;
}

.btn-voted {
  color: #FFFFFF;
  background-color: #CC0000;
  padding-left: 40px;
}

.btn-voted:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: "\f14a";
}

.btn-voted:hover {
  color: #FFFFFF;
  background-color: #A30000;
}

.btn-unvote {
  color: #CC0000;
  background: linear-gradient(#FFFFFF, #F4CCCC);
  padding-left: 30px;
  text-shadow: #FFFFFF 2px 1px;
}

.btn-unvote:before {
  background: #FFFFFF;
  content: "";
  height: 15px;
  width: 15px;
  left: 20px;
  top: 11px;
  left: 15px;
  border: solid 1px;
  border-color: #999999 #f9e5e5 #f9e5e5 #999999;
}

.btn-unvote:hover {
  color: #CC0000;
  background: linear-gradient(#FFFFFF, #EFB7B7);
}

.btn-save-vote,
.btn-confirm-vote {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  width: 240px;
  white-space: nowrap;
}

.btn-save-vote {
  color: #5A6064;
  background-color: #F8F9FA;
  border: solid 2px #5A6064;
}

.btn-save-vote:hover {
  background-color: #E2E6EA;
}

.btn-confirm-vote {
  color: #FFFFFF;
  background-color: #CC0000;
  border: solid 2px #CC0000;
}

.btn-confirm-vote:hover {
  color: #FFFFFF;
  background-color: #A30000;
}

.btn-confirm-vote > span.badge {
  position: absolute;
  top: -10px;
  right: -20px;
}

.confirm-message {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .btn-save-vote,
  .btn-confirm-vote {
    font-size: 16px;
    width: 150px;
  }

  .confirm-message {
    font-size: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .btn-voted,
  .btn-unvote {
    font-size: 12px;
    width: 130px;
  }

  .btn-save-vote,
  .btn-confirm-vote {
    font-size: 12px;
    width: 120px;
  }

  .btn-confirm-bill {
    font-size: 10px;
  }

  .confirm-message {
    font-size: 12px;
  }
}

  /**************************************************
   * .*-script
   **************************************************/
.enable-script {
  display: none;
}

.no-script {
  animation: 0.3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}




.vote-box,
.result-box {
    background-color: #EEEEEE;
    border: 1px solid #444444;
    text-align:center;
}

.vote-box {
  padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right:20px;
    margin-bottom: 25px;
}


@media (max-width: 992px) {
  .result-box {
    font-size: 0.8rem;
  }
}

@media (min-width: 1200px) {
  .result-box {
    font-size: 1.5rem;
  }
}

.vote-box div.candidate {
    background-color: #FFFFFF;
    text-align: center;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    cursor: hand;
}

.vote-box div.candidate .profile {
  margin-bottom: 0.75rem;
}

.vote-box div.candidate .name,
.result-box .name {
    background-color: #666666;
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    word-break: break-all
}

div.candidate .name {
    margin-right: auto;
    margin-left: auto;
    margin-bottom:10px;
}

.result-box > div.row {
  border-bottom: 1px solid #666666;
  margin-right: 0;
  margin-left: 0;
  padding: 1rem;
}

.result-box .col-img {
  padding: 0;
}

.result-box.aggregate .col-img {
  top: 40px;
}

.result-box img.small {
  max-height: 80%;
  max-width: 80%;
}


.winner-circle,
.approval-circle,
.denial-circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: red;
  color: white;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.winner-circle:before,
.approval-circle:before,
.denial-circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.winner-circle:after {
  content: "当選";
  font-size: 125%;
  vertical-align: middle;
}

.approval-circle {
  background: #04A2EB;
}

.approval-circle:after {
  content: "可決";
  font-size: 100%;
  vertical-align: middle;
}

.denial-circle {
  background: #ee285f;
}

.denial-circle:after {
  content: "否決";
  font-size: 100%;
  vertical-align: middle;
}

.election-schedule,
.bill-schedule {
  background-color: #EEEEEE;
  border: 1px solid #999999;
  margin-bottom: 1.5rem;
}


.election-schedule .head,
.bill-schedule .head {
  padding:1rem 1rem 0 1rem;
}

.election-schedule .head .title:before,
.bill-schedule .head .title:before {
  content: '■';
}

.election-schedule .head .data,
.bill-schedule .head .data {
  padding-left :2rem;
  overflow-wrap: break-word;
}

.election-schedule .body,
.bill-schedule .body {
  background-color: #FFFFFF;
  border-top: 1px solid #cccccc;
  margin-top: 1rem;
  padding: 1rem 0.5rem 0 0.5rem;
}




.group-checks {
  float: right !important;
}

.group-checks label,
.group-checks label:before {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.group-checks *[class*="btn-select"] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  color: #212529;
  background-color: #f9fafa;
  border: 1px solid;
  border-radius: 0.2rem;
}

.group-checks *[class*="btn-select"].active:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f14a';
  margin-right: 5px;
  margin-top: 2px;
}

.group-checks *[class*="btn-select"]:not(.active):before {
  font-family: 'Font Awesome 5 Free';
  content: '\f0c8';
  margin-right: 5px;
  margin-top: 2px;
}

.group-checks .btn-select01:hover {
  background-color: #b2bec7;
  border-color: #b2bec7;
}

.group-checks .btn-select02:hover {
  background-color: #a2a2f7;
  border-color: #a2a2f7;
}

.group-checks .btn-select03:hover {
  background-color: #b2c4ab;
  border-color: #b2c4ab;
}

.group-checks .btn-select01.active {
  color: #f8f9fa;
  background-color: #91a3b0;
  border-color: #91a3b0;
}

.group-checks .btn-select02.active {
  color: #f8f9fa;
  background-color: #6565f3;
  border-color: #6565f3;
}

.group-checks .btn-select03.active {
  color: #f8f9fa;
  background-color: #668957;
  border-color: #668957;
}






/**************************************************
 * bill
 **************************************************/

.bill-title-box {
  margin-bottom: 2em;
  word-wrap:break-word;
}

.bill-title-box p {
  margin-bottom: 0;
}

.bill-title-box .bill-title:before {
  content: 'タイトル(議題)：';
  padding-right:25px;
}

.bill-title-box .bill-open-date:before {
  content: '議案公開日時：';
  padding-right:25px;
}

.bill-title-box .bill-term:before {
  content: '議決権行使期間：';
  padding-right:10px;
}

.bill-title-box .bill-result:before {
  content: '結果公開終了日：';
  padding-right:10px;
}

.bill-tally-bar {
  align-items: center !important;
  height: 100% !important;
}

.bill-tally-bar > div {
  display:flex;
  font-size: 80%;
  font-weight: 400;
}

.bill-tally-bar .approval,
.bill-tally-bar .denial,
.bill-tally-bar .other {
  color:#FFFFFF;
  text-align:center;
}

.bill-tally-bar .approval {
  background-color:#04A2EB;
}

.bill-tally-bar .denial {
  background-color:#ee285f;
}

.bill-tally-bar .other {
  background-color:#C8C8C8;
}

.bill-tally-tooltip {
  font-size: 90%;
}

.bill-tally-tooltip:before {
  content: '■';
  padding-right:5px;
}

.bill-tally-tooltip.approval:before {
  color:#04A2EB;
}

.bill-tally-tooltip.denial:before {
  color:#ee285f;
}

.bill-tally-tooltip.other:before {
  color:#C8C8C8;
}

@media (min-width: 992px) {
  .bill-tally-detail {
    font-size: 100%;
  }
}

@media (max-width: 992px) {
  .bill-tally-detail {
    font-size: 80%;
  }
}

.bill-tally-detail .approval:before,
.bill-tally-detail .denial:before,
.bill-tally-detail .other:before {
  content: '■';
  padding-right:1px;
}

.bill-tally-detail .approval:before {
  color:#04A2EB;
}

.bill-tally-detail .denial:before {
  color:#ee285f;
}

.bill-tally-detail .other:before {
  color:#C8C8C8;
}

.bill-outer-box {
  background-color: #eeeeee;
  border: 1px solid #444444;
  padding: 10px 10px;
  margin-bottom: 20px;
}

.bill-panel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bill-panel .bill-header {
  background-color: #999;
  padding: 10px;
  width: 100%;
  min-height: 50px;
}

.bill-panel .bill-header label {
  color: #ffffff;
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 0;
  overflow-wrap: break-word;
  vertical-align: middle;
}

.bill-panel .bill-header button {
  float: right;
}

@media (min-width: 992px) {
  .bill-panel .bill-header label {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .bill-panel .bill-header label {
    width: 70%;
  }
}

.bill-panel .bill-body {
  background-color: #fcfcfc;
  border: 1px solid #c0c0c0;
  padding: 0.5rem;
}

.bill-panel .bill-body .bill-title {
  font-size: 1.25em;
  overflow-wrap: break-word;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}

.bill-panel .bill-body .bill-nest {
  padding-left: 15px;
}

.bill-panel .bill-body .bill-proxy {
  text-align: right;
}

.bill-panel .bill-body .bill-proxy label {
  color: #476B80;
  border-bottom: 1px solid;
  cursor: default;
  font-size: 1.5em;

}

.bill-panel .bill-body .bill-result {
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .bill-panel .bill-body .bill-result {
    text-align: center !important;
  }
}


.bill-checks {
  float: right !important;
}

.bill-checks label,
.bill-checks label:before {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.bill-checks .btn-approval,
.bill-checks .btn-denial,
.bill-checks .btn-other,
.bill-checks .btn-entrust {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  color: #212529;
  background-color: #f9fafa;
  border: 1px solid;
  border-radius: 0.2rem;
  margin-left: -1px;
  margin-right: -1px;
}

.bill-checks .btn-approval.active:before,
.bill-checks .btn-denial.active:before,
.bill-checks .btn-other.active:before,
.bill-checks .btn-entrust.active:before  {
  font-family: 'Font Awesome 5 Free';
  content: '\f14a';
  margin-right: 5px;
  margin-top: 2px;
}

.bill-checks .btn-approval:not(.active):before,
.bill-checks .btn-denial:not(.active):before,
.bill-checks .btn-other:not(.active):before,
.bill-checks .btn-entrust:not(.active):before {
  font-family: 'Font Awesome 5 Free';
  content: '\f0c8';
  margin-right: 5px;
  margin-top: 2px;
}

.bill-checks .btn-approval:hover {
  background-color: #a2a2f7;
  border-color: #a2a2f7;
}

.bill-checks .btn-denial:hover {
  background-color: #f693af;
  border-color: #f693af;
}

.bill-checks .btn-other:hover {
  background-color: #b2c4ab;
  border-color: #b2c4ab;
}

.bill-checks .btn-entrust:hover {
  background-color: #dfc9f5;
  border-color: #dfc9f5;
}

.bill-checks .btn-approval.active {
  color: #f8f9fa;
  background-color: #6565f3;
  border-color: #6565f3;
}

.bill-checks .btn-denial.active {
  color: #f8f9fa;
  background-color: #ee285f;
  border-color: #ee285f;
}

.bill-checks .btn-other.active {
  color: #f8f9fa;
  background-color: #668957;
  border-color: #668957;
}

.bill-checks .btn-entrust.active {
  color: #f8f9fa;
  background-color: #ae79e6;
  border-color: #ae79e6;
}

.bill-panel .bill-confirm .row {
  padding: 5px;
  width: 100%;
  margin-left: 0;
}

.bill-panel .bill-confirm .approval {
  background-color: #aaaaff;
  color: #12126d;
}

.bill-panel .bill-confirm .denial {
  background-color: #ffaaaa;
  color: #670924;
}

.bill-panel .bill-confirm .other {
  background-color: #93ac89;
  color: #1e291a;
}

.bill-panel .bill-confirm .entrust {
  background-color: #c6a1ed;
  color: #342445;
}

.bill-panel .bill-confirm .unselected {
  background-color: #eeeeee;
  color: #5f5f5f;
}

.bill-panel .bill-confirm .unselected .select {
  color: #cc0000;
}

.bill-panel .bill-confirm div {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}

.bill-panel .bill-confirm .select {
  margin-bottom: 0;
  font-size: 1.5em;
  font-weight: 700;
  justify-content: right;
}

.bill-table-analyze {
  table-layout: auto !important;
  text-align: center;
  white-space: nowrap;
}

.bill-ellipsis {
  padding: 3px;
}

.bill-ellipsis .bill-body {
  margin-top: 1px ;
  margin-bottom: 1px;
  background-color: #fcfcfc;
  border: 1px solid #c0c0c0;
  padding: 0.25rem;

}

.bill-ellipsis .bill-title {
  margin-bottom: 0;
  font-size: 1.1em;
}

.bill-selected {
  padding-right: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  text-align: right;
  font-size: 1.1em;
}

.bill-selected .approval {
  color: #6565f3;
  border-bottom: 1px solid;
}

.bill-selected .denial {
  color: #ee285f;
  border-bottom: 1px solid;
}

.bill-selected .other {
  color: #668957;
  border-bottom: 1px solid;
}

.bill-selected .entrust {
  color: #ae79e6;
  border-bottom: 1px solid;
}



.preview-area {
  display: flex;
  flex-direction: column;
}

.preview-content {
  flex-grow: 1;
}












/* TODO 議決リファクタリング後削除(ここから) ----------------------------------------- */

.gray-bill-vote-box {
    background-color: #eeeeee;
    border: 1px solid #444444;
    padding: 20px 10px;
    margin-bottom: 25px;
}
.bill-form-header {
    background-color: #999;
    padding: 10px;
    width: 100%;
  min-height: 50px;
}
.bill-form-header label {
  color: #ffffff;
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 0;
  overflow-wrap: break-word;
  vertical-align: middle;
}
/*
.bill-form-header label {
  color: #ffffff;
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}
*/
.white-bill-vote-box {
    background-color: #FFFFFF;
    border: solid 1px ;
    border-color: #c0c0c0;
}
.white-bill-vote-box .proxy-inactive {
    color: #c0c0c0;
}
.bill-item .bill-item-title {
  font-size: 1.25em;
  overflow-wrap: break-word;
}
/*
.bill-item .bill-item-title {
  font-size: 1.25em;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
*/
.bill-item .bill-item-detail {
  padding-left: 40px;
}
.bill-item .bill-item-detail .bill-item-detail-title {
  font-size: 1.25em;
  overflow-wrap: break-word;
}
.bill-radio-box label {
    font-size: 1.5em;
    color: #898989;
    border-bottom: 1px solid;
    border-bottom-color: transparent;
}
.bill-radio-box .radio-inline {
    display: inline;
    padding-left: 10px;
}
.bill-radio-box input[type="radio"]:checked + label.red,
.under-red {
    color: #ee285f;
    border-bottom: 1px solid;
}
.bill-radio-box input[type="radio"]:checked + label.blue,
.under-blue {
    color: #6565f3;
    border-bottom: 1px solid;
}
.bill-radio-box input[type="radio"]:checked + label.green,
.under-green {
  color: #668957;
  border-bottom: 1px solid;
}
.bill-vote-form .bill-radio-box input:hover,
.bill-vote-form .bill-radio-box label:hover {
    cursor: pointer;
}
.inactive-color label,
.inactive-color input {
    color: #c0c0c0;
    cursor: not-allowed !important;
}
.white-gradation-btn {
    background: linear-gradient(180deg, #fff, #fff, #f1f1f1, #afafaf);
    border: 2px solid #999;
    border-bottom: 2px solid #999;
    color: #666 !important;
    display: inline-block;
    text-shadow: 0px 0px 0px #999, 1px 1px #fff;
    width: 100% !important;
}
.red-gradation-btn {
    background: linear-gradient(180deg, #f39191, #ea4a4a, #cc2929, #7d0606);
    border: 2px solid #999;
    border-bottom: 2px solid #999;
    color: #fff !important;
    display: inline-block;
    text-shadow: 1px 1px 1px #710f0b, 1px 1px 3px #000;
    width: 100% !important;
}
.red-gradation-btn[disabled],
.white-gradation-btn[disabled] {
    cursor: not-allowed !important;
}
.bill-proxy-state .bill-proxy-confirm-box {
    text-align: center;
    padding: 5px;
    width: 100%;
    background-color: #ffffff;
    color: #1b1b1b;
    border: solid 1px ;
    border-color: #c0c0c0;
}
.bill-item-state .bill-item-approval-box {
    text-align: center;
    padding: 5px;
    width: 100%;
    background-color: #aaaaff;
    color: #12126d;
    border: solid 1px ;
    border-color: #8080ff;
}
.bill-item-state .bill-item-denial-box {
    text-align: center;
    padding: 5px;
    width: 100%;
    background-color: #ffaaaa;
    color: #670924;
    border: solid 1px ;
    border-color: #ff8080;
}
.bill-proxy-state .bill-check-label,
.bill-item-state .bill-check-label {
    margin-bottom: 0px;
    font-size: 1.5em;
}
p.confirm-button-inactive {
    color: #670924;
}
/* 他集計票の登録画面. */
.bill-vote-form .bill-item-mini label {
    margin-bottom: 0em;
    font-size: 1.1em;
}
.bill-item-detail-wrapper {
    border: solid 1px;
    border-color: #c0c0c0;
    padding-bottom: 4px;
}
.bill-result .bill-intention {
  font-size: 1.25rem;
  font-weight: 300;
}
label.active-label {
    color: #0073ff;
}
label.active-label:hover {
    cursor: pointer !important;
    color: #88bdff !important;
}
/* TODO 議決リファクタリング後削除(ここまで) ----------------------------------------- */

/* 議決権行使状況. */
h2.bill-rate {
  font-size: 36px;
  text-align: center;
}
h2.bill-rate:before {
  font-size: 24px;
  content: '行使率：';
}
h2.bill-rate:after {
  font-size: 24px;
  content: '%';
}
h2.bill-count {
  font-size: 24px;
  text-align: center;
}
h2.bill-count:before {
  content: '(';
}
h2.bill-count:after {
  content: ')';
}
.bill-result > .approval-circle,
.bill-result > .denial-circle {
    font-size: 1.25rem;
    font-weight: 600;
}



/*
.has-info .help-block,
.has-info .col-form-label,
.has-info .radio,
.has-info .checkbox,
.has-info .radio-inline,
.has-info .checkbox-inline
 {
  color: #0000CD;
}
*/
.has-info .form-control {
  border-color: #0000FF;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
/*
.has-info .form-short-post-tel {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-info .form-post-tel {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-info .form-control:focus {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #0000CD;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #0000CD;
}
.has-info .input-group-addon {
  color: #0000CD;
  background-color: #0000CD;
  border-color: #0000CD;
}
.has-info .form-control-feedback {
  color: #0000CD;
}
*/


.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

@media (min-width: 768px) {
  .w-md-10 {
    width: 10% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .h-md-10 {
    height: 10% !important;
  }

  .h-md-20 {
    height: 20% !important;
  }

  .h-md-30 {
    height: 30% !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .h-md-70 {
    height: 70% !important;
  }

  .h-md-80 {
    height: 80% !important;
  }

  .h-md-90 {
    height: 90% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .h-lg-10 {
    height: 10% !important;
  }

  .h-lg-20 {
    height: 20% !important;
  }

  .h-lg-30 {
    height: 30% !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-70 {
    height: 70% !important;
  }

  .h-lg-80 {
    height: 80% !important;
  }

  .h-lg-90 {
    height: 90% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .top-lg-10 {
    position: relative;
    top: 10px;
  }

  .top-lg-40 {
    position: relative;
    top: 40px;
  }
}


.min-width-100 {
  min-width:100px;
}

.ms-n5 {
  margin-left: -40px;
}

.green-box1 {
    background-color: rgb(34, 146, 8);
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}
.green-box2 {
    margin-bottom: 25px;
    white-space: nowrap;
    background-color: rgba(88, 167, 70, 0.7);
}
.green-box3 {
    margin-bottom: 25px;
    white-space: nowrap;
    background-color: rgba(137, 193, 125, 0.64);
}
.green-box4 {
    margin-bottom: 25px;
    background-color: rgba(179, 215, 172, 0.49);
    white-space: nowrap;
}
.table-green-bordered>tbody>tr>td {
    border: 1px solid rgba(34, 146, 8, 0.4);
}
.table-green-bordered>tbody>tr>th {
    border: 1px solid rgba(34, 146, 8, 0.4);
}
.green_box5 {
    background-color:  rgba(179, 215, 172, 0.49);
    border: 1px solid rgb(34, 146, 8);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-bottom: 25px;
}


.hidden-file {
  visibility:hidden;
  position:absolute;
  top:0;
}

.image-select-area {
  max-width:500px;
}

@media (max-width: 576px) {
  .image-select-area {
    max-width:350px;
  }
}

.image-preview-area {
  width:180px;
  height:240px;
  overflow:hidden;
}

.hidden {
  display:none !important;
}

.all-cnt:before {
  content: '全 ';
}

.all-cnt:after {
  content: ' 件';
}

.err-cnt:before {
  content: 'エラー件数 : ';
}

.err-cnt:after {
  content: ' 件';
}


.side-full .sub-menu {
  border-right: 1px solid #999;
}

.side-full .sub-menu ul {
  padding-left: 0;
  list-style: none;
}

.side-full .sub-menu .badge {
  float: right !important;
  margin-top: 0.25rem;
}

.tooltip-inner {
  max-width: 100%;
}

.inf-scroll #loader {
  height: 0;
  overflow: hidden;
  line-height: 60px;
}

.inf-scroll #loader.active {
  height: 60px;
}

#loader > .spinner-border {
  width: 3rem;
  height: 3rem;
}

.line-h-40 {
  line-height: 40px;
}

.border-bottom-black {
  border-bottom: 1px solid #000000 !important;
}


.text-ellipsis {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-ellipsis.hov-ellipsis:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}


/*######################################################################################################
 *
 * Summernote
 *
 *####################################################################################################*/

div.note-editable p,
div.sn-text p {
  margin-bottom: 0;
  margin-left: 0;
}


/*============================ common.css  =======================================*/
/*

.rate {
    text-align: center;
    font-size: 24px;
    margin-bottom: 2em;
}

.control-label .user-attribute {
    color: #0000CD;
    font-size: 12px;
    padding-left: 2px;
}
.has-primary .help-block,
.has-primary .control-label,
.has-primary .radio,
.has-primary .checkbox,
.has-primary .radio-inline,
.has-primary .checkbox-inline {
  color: #0000CD;
}
.has-primary .form-control {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-primary .form-short-post-tel {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-primary .form-post-tel {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-primary .form-control:focus {
  border-color: #0000CD;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #0000CD;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #0000CD;
}
.has-primary .input-group-addon {
  color: #0000CD;
  background-color: #0000CD;
  border-color: #0000CD;
}
.has-primary .form-control-feedback {
  color: #0000CD;
}

.iv-help .iv-bold {
  color: #843534;
  font-weight:bold;
}
.iv-help .iv-red {
  color: #FF0000;
}
.iv-help .iv-blue {
  color: #0000CD;
}


.iv-border {
  border-left: 1px solid #999;
}
.iv-panel-inner {
  border-style: solid;
  border-color: #000000;
}
.iv-table-border {
  background-color: #fff;
  border-right: 1px solid #DDD;
  border-left: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
}
.iv-table-border-top {
  border-top: 1px solid #DDD;
}
.iv-table-border-head {
  background-color: #EEE;
}
.iv-panel-inner > .iv-panel-heading {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.iv-panel-inner > .iv-panel-body {
  border-style: solid;
  border-width : 3px;
  border-color: #000000;
}




.no-block {
    display: inline;
}

.pop-msg {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  color: #fff;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25em;
}

i.icn-color {
    color: #a52a2a;
}
*/

/*===========================================  login.css  =============================================*/


/*============================================  admin.css  ============================================*/
/*
body#admin .panel {
    background-color: #f1f5d7;
}


body#admin div#content {
    background-color: #f1f5d7;
}
div#infomation-inner {
    margin-right: 1px;
    padding: 0 1em 0.5em 1em;
    height: 220px;
    overflow-y: scroll;
}

div#content table.admin-top {
    border: 1px solid #999999;
    font-size: 0.8em;
    font-weight: normal;
}
div#content table.admin-top thead th {
    background-color: #666666;
    padding: 1em;
    border: none;
    color: #ffffff;
}
div#content table.admin-top tbody td {
    padding: 1em;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
}
div#content table.admin-top tbody tr:nth-child(even) {
    background-color: #eeeeee;
}
#search_box {
    background-color: #EEEEEE;
    border: 1px solid #999999;
    padding: 1em;
}
.search_box {
    background-color: #EEEEEE;
    border: 1px solid #999999;
    padding: 1em;
}
.search_btn {
    text-align: center;
}

.scedule_main .row {
    margin-bottom: 10px;
}
.white_space {
    background-color: #FFFFFF;
    padding:1em;
}
.black_ttl {
    background-color: #666666;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    margin-bottom: 25px;
    color: #FFFFFF;
}
#pageSizeSelector {
  display: inline-block;
  padding:2px 6px;
  margin-bottom: 5px;
  vertical-align: middle;
}
.ok_all {
    padding-bottom: 20px;
    border-bottom: 1px dotted #666666;
    margin-bottom: 30px;
}
.ok_all .table tr th {
    background-color: #EEEEEE;
}
.ok_all .img-responsive {
    margin-right: auto;
    margin-left: auto;
}
.ok_all .search_btn {
    margin-bottom: 20px;
    margin-top:0.5em;
}
.ok_all table {
    margin-bottom: 0.5em;
}
.white_space .row .col-md-10 {
    border-left: 1px solid #999999;
}
.white_space .row .col-md-2 {
    margin-bottom: 2em;
}
.return_btn {
    float: right;
    line-height: 2em;
    margin-right:0.5em;
}
.white_space .rate {
    margin-bottom: 8em;
}
.pager img {
    vertical-align:middle;
    margin-bottom:0.5em;
}
.pager li a {
    background: none !important;
    border: none !important;
}
.arrow_return {
    display: inline;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.search_botton_size {
    width: 230px;
}

.ok_profile {
    padding-bottom: 20px;
}

.picture {
    padding: 0px;
}

.applicant_photo {
    width:180px;
    height:240px;
    overflow:hidden;
}

.config {
    font-size: 20px;
    font-weight: 700;
    width: auto;
}

.app-cursor-pointer {
    color: blue;
    cursor: pointer;
}

nav.ivote_side ul li a {
    padding: 10px 1px 10px 39px;
    text-indent: 0px;
}

.incompleteWarning {
   background-color: #FFFF66;
}

.noAproval{
    font-size: 20px;
    padding-bottom: 20px;
}

.admin_message_err {
    border: 1px solid #a94442;
}

.radio-button input{
    display: none;
}
.radio-button label{
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-left: 20px;
    padding: 10px 20px;
    border-radius: 2px;
    color: #3e4956;
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    line-height: 1;
}
.radio-button label:before{
    position: absolute;
    content: "";
    top: 50%;
    left: -10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background: #bdc3c7;
    border-radius: 50%;
}
.radio-button input[type="radio"]:checked + label:after {
    position: absolute;
    content: "";
    top: 50%;
    left: -4px;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    border-radius: 50%;
    background: #0000ff;
}
.radio-button input[type="radio"]:checked:disabled + label:after {
    background: #808080;
    cursor: not-allowed;;
}
.radio-button input[type="radio"]:disabled + label {
    cursor: not-allowed;;
}
*/


/*===================================  user.css  ===========================================================*/
/*




body#user div#content {
    background-color: #ffffff;
}
.panel.wrap-infomation {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px dotted #7f7f7f;
}

#search_box {
    background-color: #EEEEEE;
    border: 1px solid #999999;
    padding: 1em;
}
.search-box {
    background-color: #EEEEEE;
    border: 1px solid #999999;
    padding: 1em;
}
.search_btn {
    text-align: center;
}

#pageSizeSelector {
  display: inline-block;
  padding:2px 6px;
  margin-bottom: 5px;
  vertical-align: middle;
}
.pager img {
    vertical-align:middle;
    margin-bottom:0.5em;
}
.pager li a {
    background: none !important;
    border: none !important;
}
.arrow_return {
    display: inline;
    margin-right: 0.5em;
    margin-left: 0.5em;
}





.vote_box {
    background-color: #FFFFFF;
    text-align: center;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    cursor: hand;
}
.vote_box .black_box {
    margin-right: auto;
    margin-left: auto;
    margin-bottom:10px;
}
.vote_box a {
    display: inline-block;
    margin-bottom: 5px;
}
.vote_box .img-responsive {
    margin-left:auto;
    margin-right:auto;
}
.vote_btn {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-bottom:10px;
}

.mixedvote_edit_gray_vote_box_padding {
    padding-left: 0;
    padding-right: 0;
}

.main_ttl.grn {
    color: #2a8f12;
    border-top-color: #2f9f14;
    border-bottom-color: #2f9f14;
}

.gray_vote_box thead {
    color:#FFFFFF;
    background-color:#666666;
}
.gray_vote_box tbody {
    background-color:#FFFFFF;
}
.result_all td{
    text-align: left;
    vertical-align: middle;
}
.result_all tbody tr td {
    text-align: left;
    vertical-align: middle;
}
.result_all td.text-right {
    text-align: right;
}
.result_all td.rank {
    width: 15%;
}
.result_all td.win {
    width: 15%;
}
.result_all td.thum {
    width: 15%;
}
.result_all td.name {
    width: 30%;
}
.result_all td.percent {
    width: 25%;
}
.side_line {
    border-left: 1px dotted #666666;
}
.table.result_all .img-responsive {
    width: 80%;
}

.result_row {
    border-bottom: 1px solid #666666;
    margin-bottom: 1em;
}


.add_all {
    float: right;
}

.file_download {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 0px;
}

.no_photo{
    text-align: center;
    cursor: pointer;
    cursor: hand;
}

.vote_panel{
    border-color: #666666;
}

.vote_panel_hr{
    margin:15px 0px 3px;
}

.vote_panel_td{
    margin-top:15px;
}

.vote_panel_img{
    margin-top:10px;
}

.result_panel_body{
    border-bottom: 3px solid #c0c0c0;
    padding: 5px;
}

.result_icn_win{
    margin-top: 6px;
    height: 42px;
}

.result_candidate_name{
    font-weight: bold;
    font-size: 20px;
    margin-top: 12px;
}

.voted_obtain{
    margin-top: 3px;
}

.nophoto_candidate_name{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 3px;
}

.vpted_text_center{
    text-align: center;
    font-size: 24px;
    margin-bottom: 5px;
}

.picture {
    padding: 1px;
}

.own_photo {
    width:180px;
    height:240px;
    overflow:hidden;
}

.result_edit td{
    text-align: left;
    vertical-align: middle;
}
.result_edit tbody tr td {
    text-align: left;
    vertical-align: middle;
}
.result_edit td.text-right {
    text-align: right;
}
.result_edit td.rank {
    width: 15%;
}
.result_edit td.win {
    width: 15%;
}
.result_edit td.thum {
    width: 15%;
}
.result_edit td.name {
    width: 30%;
}
.result_edit td.percent {
    width: 25%;
}
.table.result_edit .img-responsive {
    width: none;
}
a.nav-item.bg-primary {
    background: #c0c0c0!important;
}
.nav-tabs>li>a {
    background-color: rgba(114, 121, 69, 0.31);
}
.nav-tabs>li>a:link {
    color: #f1f5d7;
}
.nav-tabs>li.active>a {
    color: #333;
}
.nav>li>a:hover {
    background-color: rgba(153, 153, 153, 0.65);
}

.green_vote_box {
    background-color: #EEEEEE;
    border: 1px solid #229208;
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 25px;
    text-align: center;
}
.noAproval{
    font-size: 20px;
    padding-bottom: 20px;
}
*/
