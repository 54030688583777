@charset "utf-8";

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg) scale(1.2);
  }
  30% {
    transform: rotate(0deg) scale(1.2);
  }
  40% {
    transform: rotate(-10deg) scale(1.2);
  }
  50% {
    transform: rotate(0deg) scale(1.2);
  }
  60% {
    transform: rotate(5deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  80% {
    transform: rotate(-5deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg);
  }
}


/**************************************************
 * .sidebar
 **************************************************/
.sidebar {
  background-color: #ececec;
  margin-top: 1em;
  min-height: 100%;
  color: #212529;
}

.sidebar .panel-heading {
  font-size: 0.875rem;
  margin-bottom: 0px;
}

.sidebar .heading-voter {
  border-bottom: 1px solid #666666;
  background: -webkit-gradient(linear,left top,left bottom,from(#eeeeee),to(#bcbcbc));
  background: -moz-linear-gradient(top,#eeeeee,#bcbcbc);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#FFeeeeee', endColorstr='#FFbcbcbc');
}

.sidebar .heading-management {
  border-bottom: 1px solid #666666;
  background: -webkit-gradient(linear,left top,left bottom,from(#fff7f8),to(#e9b6ac));
  background: -moz-linear-gradient(top,#fff7f8,#e9b6ac);
  background: linear-gradient(to bottom, #fff7f8,#e9b6ac);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#FFfff7f8', endColorstr='#FFe9b6ac');
}

.heading-management > .title {
  padding: 0.2em;
  margin-bottom: 0.5em;
  background-color: #000000;
  color: #ffffff;
  border: 3px double #ffffff;
  line-height: 1.6em;
  font-weight: 600;
  text-align: center;
}


/**************************************************
 * nav
 **************************************************/
nav.navbar {
  padding: 0px;
}


/**************************************************
 * .navbar-toggler
 **************************************************/
.navbar-toggler {
  margin: 0.5em;
}


/**************************************************
 * .nav-content
 **************************************************/
.nav-content {
  width: 100%;
}


/**************************************************
 * .nav-menu
 **************************************************/
.nav-menu {
  width: 100%;
}

.nav-menu a {
  text-decoration: none;
}

.nav-menu a,
.nav-menu span {
  color: #242423;
}

.nav-menu ul {
  list-style: none;
}

.nav-menu > ul {
  padding-left: 0px;
}

.nav-menu > ul > li {
  border-top: 1px solid #fafff5;
  border-bottom: 1px solid #b8beb4;
  padding-right: 0px;
}


/**************************************************
 * .nav-menu  .icon-**
 **************************************************/
 /*
.nav-menu [class*="icon-"]:before {
  width: 36px;
  height: 36px;
  content: " ";
  position: absolute;
  left: -20px;
  top: 0.5em;
}

.nav-menu .icon-home:before {
  background: url(/images/icn_home.png) 3px center no-repeat;
}

.nav-menu .icon-profile:before {
  background: url(/images/icn_profile.png) 3px center no-repeat;
}

.nav-menu .icon-vote:before {
  background: url(/images/icn_vote.png) 3px center no-repeat;
}

.nav-menu .icon-hand:before {
  background: url(/images/icn_hand.png) 3px center no-repeat;
}

.nav-menu .icon-result:before {
  background: url(/images/icn_result.png) 3px center no-repeat;
}

.nav-menu .icon-news:before {
  background: url(/images/icn_news.png) 3px center no-repeat;
}
*/

/**************************************************
 * .nav-menu  .awesome-**
 **************************************************/
.nav-menu [class*="awesome-"]:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: -10px;
  top: 0.5em;
  font-size:1.5rem;
}

.nav-menu .awesome-top:before {
  content: "\f015";
}

.nav-menu .awesome-address-card:before {
  content: "\f2bb";
}

.nav-menu .awesome-key:before {
  content: "\f084";
}

.nav-menu .awesome-users-cog:before {
  content: "\f509";
}

.nav-menu .awesome-vote-yea:before {
  content: "\f772";
}

.nav-menu .awesome-envelope:before {
  content: "\f0e0";
}

.nav-menu .awesome-bullhorn:before {
  content: "\f0a1";
}

.nav-menu .awesome-hand-paper:before {
  content: "\f256";
}

.nav-menu .awesome-ribbon:before {
  content: "\f4d6";
}

.nav-menu .awesome-newspaper:before {
  content: "\f1ea";
}

.nav-menu .awesome-balance:before {
  content: "\f24e";
}

.nav-menu .awesome-balance-scale-left:before {
  content: "\f515";
}

.nav-dropdown [class*="awesome-"]:before {
  left: 20px !important;
}


/**************************************************
 * .nav-link
 **************************************************/
.nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
}


/**************************************************
 * .nav-title
 **************************************************/
.nav-title {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 12px 30px 10px 30px;
}


/**************************************************
 * .nav-dropdown
 **************************************************/
.nav-dropdown > .nav-title {
  cursor: pointer;
  position: relative;
  padding-left: 60px;
}

.nav-dropdown > .nav-title:before {
  position: absolute;
  left: 10px;
}

.nav-dropdown > .nav-title:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.nav-dropdown.active > .nav-title:after {
  transform: rotate(90deg);
  right: 17px;
}


/**************************************************
 * .nav-submenu
 **************************************************/
.nav-submenu {
  display: none;
}

.nav-submenu ul {
  padding: 5px 0;
  background: #f9f9f9;
}

.nav-submenu li {
  padding-left: 25px;
  font-size: 0.8rem;
}

.nav-submenu li a span:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.nav-submenu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}


/**************************************************
 * hover
 **************************************************/
.nav-submenu a:hover span,
.nav-link:hover a > span,
.nav-dropdown:hover .nav-title > span {
  color: #000000;
}

.nav-link:hover .nav-title::before,
.nav-dropdown:hover .nav-title::before {
  animation: swing ease-in-out 0.5s 1 alternate;
}

#voter-menu .nav-link:hover [class*="awesome-"]::before,
#voter-menu .nav-dropdown:hover [class*="awesome-"]::before,
#voter-menu .nav-submenu a:hover span::before {
  color: #6666FF;
  text-shadow: 0px 0px 10px rgba(102, 102, 255, 0.5);
}

#management-menu .nav-link:hover [class*="awesome-"]::before,
#management-menu .nav-dropdown:hover [class*="awesome-"]::before,
#management-menu .nav-submenu a:hover span::before {
  color: #FF6666;
  text-shadow: 0px 0px 10px rgba(255, 102, 102, 0.5);
}
