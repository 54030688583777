@charset 'utf-8';

[class*="fontawesome-"]:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

button[class*="fontawesome-"]:before {
  margin-right: 0.5em
}


.fontawesome-align-left:before {
  content: "\f036";
}

.fontawesome-book-open:before {
  content: "\f518";
}

.fontawesome-calculator:before {
  content: "\f1ec";
}

.fontawesome-check:before {
  content: "\f00c";
}

.fontawesome-check-circle:before {
  content: "\f058";
}

.fontawesome-check-square:before {
  content: "\f14a";
}

.fontawesome-cog:before {
  content: "\f013";
}

.fontawesome-edit:before {
  content: "\f044";
}

.fontawesome-envelope:before {
  content: "\f0e0";
}

.fontawesome-eraser:before {
  content: "\f12d";
}

.fontawesome-eye:before {
  content: "\f06e";
}

.fontawesome-eye-slash:before {
  content: "\f070";
}

.fontawesome-file-download:before {
  content: "\f56d";
}

.fontawesome-file-pdf:before {
  content: "\f1c1";
}

.fontawesome-file-signature:before {
  content: "\f573";
}

.fontawesome-file-upload:before {
  content: "\f574";
}

.fontawesome-image:before {
  content: "\f03e";
}

.fontawesome-key:before {
  content: "\f084";
}

.fontawesome-marker:before {
  content: "\f5a1";
}

.fontawesome-minus:before {
  content: "\f068";
}

.fontawesome-pen-nib:before {
  content: "\f5ad";
}

.fontawesome-plus:before {
  content: "\f067";
}

.fontawesome-reply:before {
  content: "\f3e5";
}

.fontawesome-save:before {
  content: "\f0c7";
}

.fontawesome-search:before {
  content: "\f002";
}

.fontawesome-sign-out-alt:before {
  content: "\f2f5";
}

.fontawesome-sort-amount-down:before {
  content: "\f160";
}

.fontawesome-thumbs-down:before {
  content: "\f165";
}

.fontawesome-thumbs-up:before {
  content: "\f164";
}

.fontawesome-times-circle:before {
  content: "\f057";
}

.fontawesome-trash-alt:before {
  content: "\f2ed";
}

.fontawesome-angle-double-up:before {
  content: "\f102";
}

.fontawesome-user-times:before {
  content: "\f235";
}






