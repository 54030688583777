@charset 'utf-8';

@import 'normalize.css';
@import 'bootstrap';
@import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';
@import 'jquery-jcrop/css/jquery.Jcrop.css';
@import 'summernote/dist/summernote-bs4.css';

@import './app.css';
@import './font.css';
@import './sidebar.css';
